//发表垃圾分类拍照
const postRubUrl = `/gateway/hc-low-carbon/miniapi/garbage/submitActData`;
//我的运动打卡
const myHealthUrl = `/gateway/hc-low-carbon/miniapi/garbage/actDataList`;

//问卷题目
const questionListUrl = `/gateway/hc-low-carbon/miniapi/garbage/questionList`;
//垃圾分类活动主题详情
const rubTopicInfoUrl = `/gateway/hc-low-carbon/miniapi/garbage/topicInfo`;
//签名上传
const signImgUrl = `/gateway/hc-low-carbon/miniapi/garbage/submitSignData`;
//提交问卷
const submitQuestionUrl = `/gateway/hc-low-carbon/miniapi/garbage/submitQuestionnaire`;
//我最近的答题记录
const myLastAnswersUrl = `/gateway/hc-low-carbon/miniapi/garbage/myLastAnswers`;
//更新视频
const updateVideoUrl = `/gateway/hc-low-carbon/miniapi/garbage/updateStudySchedule`;
//我的答题记录
const myQuListUrl = `/gateway/hc-low-carbon/miniapi/garbage/myQuList`;
//我的答题记录详情
const recordDetailUrl = `/gateway/hc-low-carbon/miniapi/garbage/myQuInfo`;
export {
  updateVideoUrl,
  submitQuestionUrl,
  postRubUrl,
  myHealthUrl,
  questionListUrl,
  rubTopicInfoUrl,
  signImgUrl,
  myLastAnswersUrl,
  myQuListUrl,
  recordDetailUrl,
};
